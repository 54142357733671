//建设场所
const build_grounds = {
    "1":"居民区",
    "2":"公共机构",
    "3":"企事业单位",
    "4":"写字楼",
    "5":"工业园区",
    "6":"交通枢纽",
    "7":"大型文体设施",
    "8":"城市绿地",
    "9":"大型建筑配建停车场",
    "10":"路边停车位",
    "11":"城际高速服务区",
    "12":"风景区",
    "13":"公交场站",
    "14":"加油加气站",
    "15":"出租车",
    "255":"其他",
};

export {
    build_grounds
}